@mixin verdana {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: normal;
}
@mixin verdanaBold {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
}
@mixin transitionAll{
    transition: all .3s;
}
@mixin boxShadowFull {
    box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.50);
}
@mixin scaleImg{
    transform: scale(1.05);
}
@mixin  montserrat {
    font-family: 'Montserrat', sans-serif;
}
@mixin  openSans {
    font-family: 'Open Sans', sans-serif;
}
