.hero {
  .slick-slide {
    img {
      width: 100%;
    }
  }
  .slick-next {
    right: 30px;
    top: 60%;
    @include respond-to($xs, max) {
      right: 10px;
    }
  }
  .slick-prev {
    left: 30px;
    top: 60%;
    z-index: 1;
    @include respond-to($xs, max) {
      left: 10px;
    }
  }
}
.heroSlider{
  padding-top: 90px;
  margin-bottom: 0 !important;
  @include respond-to($xs, max) {
    padding-top: 70px;
  }
}
.slick-dots {
  bottom: 40px;
  @include respond-to($xs, max) {
    bottom: 5px;
  }
  li {
    height: 15px;
    width: 25px;
    overflow: hidden;
    margin: 0;
    button {
      height: 100%;
      width: 100%;
      padding: 0;
      &:before {
        content: '';
        height: 4px;
        width: 12px;
        background: #fff;
        top: 5px;
        left: 6px;
      }
    }
  }
}
.navbar-toggle{
  margin-right: 0;
  margin-top: 12px;
}
.popularProduct {
  .scaleWrap {
    display: block;
    overflow: hidden;
  }
  img {
    width: 100%;
    @include transitionAll;
  }
  h3 {
    color: $black;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
    background: #f6f6f6;
    padding: 20px 20px;
    position: relative;
    @include respond-to($xs) {
      font-size: 16px;
    }
    span {
      z-index: 2;
      position: relative;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 0;
      width: 100%;
      background: $yellow;
      z-index: 1;
      @include transitionAll;
    }
  }
  a {
    &:hover {
      h3 {
        &:after {
          height: 100%;
        }
      }
      img {
        transform: scale(1.1);
      }
    }
  }
  .productListing {
    margin: 20px 0 0 0;
    @include respond-to($xs) {
      margin: 40px 0 0 0;
    }
    .col-sm-4 {
      margin: 0 1px 0 0;
      padding: 0;
      width: 33%;
      @include respond-to($xs, max) {
        width: 49%;
      }
    }
  }
}

.aboutWrapper{
  position: relative;
  width: 100%;
  @include respond-to($xs) {
    height: 493px;
  }
}
.aboutCenter {
  margin: 0 auto;
  width: 90%;
  @include respond-to($xs) {
    margin: 0;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}