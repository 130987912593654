footer {
    background: #bb4937 url(../img/bg_footer.jpg) repeat-x top;
}
.footerWrapper {
    background: url(../img/logo-watermark.png) no-repeat right bottom;
    padding: 60px 0 30px 0;
    h3,p,address,a {
        color: $white;
        font-size: 14px;
        font-weight: 500;
    }
    h3 {
       text-transform: uppercase;
       font-size: 18px;
    }
    p.terms {
        margin-top: 30px; 
    }
}
.addressWrap {
    @include respond-to($xs, max) {
        margin-top: 30px; 
    }
}
ul.footerLinks {
    list-style: none;
    padding-bottom: 25px;
    >li {
        display: inline-block;
        @include respond-to($xs, max) {
            display: block;
            padding: 8px 0;
        }
        >a {
            padding: 0 15px;
            display: block;
            border-left: solid 2px white;
            line-height: 14px;
        }
        @include respond-to($xs) {
            &:first-child{ 
                >a {
                    padding-left: 0;
                    border-left: 0 none;
                }
            }
        }
        
    }
}

.icon-opening-wrapper .icon-opening {
    font-size: 14px;
    width: 21px;
    float: left;
}
.icon-opening-wrapper .icon-opening-content {
    overflow: hidden;
}

.social-icon-container {
    height: 35px;
    width: 35px;
    font-size: 14px;
    text-align: center;
    line-height: 31px;
    color: #FFF;
    border: 2px #FFF solid;
    display: inline-block;
    margin-right: 3px;
    border-radius: 50%;
    transition: all 0.2s ease-out;
    &:hover {
        color: #28292b;
        background-color: #FFF;
    }
    a {
        display: block;
        color: #FFF;
        transition: all 0.2s ease-out;
        &:hover {
            color: #28292b;
        }
    }
}
