html, body {
	margin: 0;
	padding: 0;
	height: 100%;
}

body {
	background-size: 100% auto;
}

* {
    box-sizing: border-box;
}

img {
	display: block;
	max-width: 100%;
	width: auto;
	height: auto;
}

fieldset,
figure {
	margin: 0;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 24px;
}

p, ul, ol, dl {
	margin-top: 0;
	margin-bottom: 0;
}

ul, ol, li {
	margin-top: 0;
	margin-right: 0; 
	margin-left: 0;
	padding: 0;
}

ul,
ol {
	padding-left: 0;
	ul,
	ol {
		margin-bottom: 0;
	}
}

a {
	color: inherit;
	text-decoration: none;

	&:focus, &:hover {
		text-decoration: none;
	}
}
