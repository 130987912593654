// Paths
$font-path: '/fonts';
$icomoon-font-path: '/fonts/icomoon/fonts';
$images-path: '../img';

$white : #ffffff;
$black: #000000;
$katthai: #bb4937;
$green : #44aa92;
$red : #f12a00;
$redBlood: #f12a00;
$blue: #284287;
$grey: #565454;
$yellow: #ffcb08;
$blueLight: #dae1f2;
$blueLight1: #f0f4ff;


//Breakpoints
//$xs : 767px;
$xsSmall: 360px;
$xs : 815px; // was 769px but for iPhoneX Landscape it made 815px
$md: 816px; // was 770px but for iPhoneX Landscape it made 815px
$lg: 1024px; 
$xlg: 1300px;
