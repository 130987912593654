.btn {
    @include montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    color: $katthai;
    background-color: $white;
    border: 3px solid $katthai;
    padding: 15px 30px;
    display: inline-block; 
    margin: 0px; 
    border-radius: 0;
    @include transitionAll;

    &:hover,
    &:focus {
        color: $black; 
        background: #f6f6f6;
        transition: all .3s; 
    }
}
.arrow {

    &::after {
        display: inline;
        padding-left: 70px;
        content: ""; 
        background-image: url(../img/arrow-green.png);
        background-repeat: no-repeat;
        background-position:  18px 3px;
        transition: background-position .4s ease-out;
        
        @include respond-to($lg, max) {
            background-position:  18px 0px;
        }
    }

    &:hover {
        &::after {
            background-image: url(../img/arrow-white.png);
            background-repeat: no-repeat;
            background-position:  28px 3px;

            @include respond-to($lg, max) {
                background-position:  28px 0px;
            }
            
        }
    }
}