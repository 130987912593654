@mixin respond-to($val, $val2: none) {
    @if $val2==none {
        @media (min-width: $val) {
            @content;
        }
    }

    @else if $val2==max {
        @media (max-width: $val) {
            @content;
        }
    }

    @else {
        @media (min-width: $val) and (max-width: $val2) {
            @content;
        } 
    }
}
html {
    overflow-y: scroll;
}
html, body {
    @include montserrat;
    color: $black;
    font-size: 15px;
    background: $white;
}
img {
    max-width: 100%;
}
.main-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    position: relative; 
}
.wrapper {
    position: relative;
}
.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1400px;
    @include respond-to($xs, max) {
        width: 100%;
    }

    
}

.sectionSpace {
    margin-bottom: 40px;
    position: relative;
    @include respond-to($xs) {
        margin-bottom: 50px;
    }
}
p {
    font-size: 14px;
    margin-bottom: 10px;
    color: $grey;
    @include openSans;
}
.lineHeight0 {
    line-height: 0; 
}
h2 {
    font-size: 28px;
    color: $black; 
    font-weight: 600; 
    @include respond-to($xs, max) {
        font-size: 20px;
        margin-bottom: 15px;
    }
    span {
        color: $katthai;
    }
}
h3 {
    font-size: 18px;
    font-weight: 600;
    color: $katthai;
}
.vCenterParent {
    display: flex;
}
.vCenterChild {
    align-self: center;
}
.btn-default {
    background-color: $grey;
    color: $white; 
    font-size: 13px;
    text-transform: uppercase;
    border-radius: 0;
    border: 0 none;
    padding: 15px 35px;
    @include transitionAll;
    &:hover{
        background-color: $red;
        color: $white;
    }
}

h2.doubleTitle {
    color: #808284;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    height: 100px;
    padding-top: 50px;
    margin-bottom: 30px;
    font-weight: 700;
    span {
        position: absolute;
        left: 0;
        font-size: 100px;
        letter-spacing: 12px;
        color: #f0f0f0;
        top: 0;
        z-index: -1;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
        
    }
    60% {
      transform: translateY(-10px);  
    }
}

.posRelative {
    position: relative;
}
.bounce {
    animation: bounce 2s infinite;
}
.pad0{ 
    padding: 0 7px !important; 
}
.padNone {
    padding: 0 0 !important; 
}
.mar0 {
    margin: 0 !important;
}
.colSpace {
    padding: 0 5px 10px 5px;
}