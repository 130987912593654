.navbar {
    border-radius: 0;
	background: url(../img/pattern.png) repeat-x left 0px;
	border: 0 none;
    margin-bottom: 0;
    position: fixed;
    z-index: 9;
    width: 100%;
    max-width: 1920px;
    border-top: solid 4px $katthai;
    height: 135px;
    @include transitionAll;
    @include respond-to($xs, max) {
        height: 80px;
        background-position: left -71px;
    }
    .navbar-header {
        margin-top: 10px;
        margin-left: 50px;
        @include respond-to($xs, max) {
            margin: 0;
            z-index: 1;
            position: relative;
        }
    }
    .navbar-nav {
        @include transitionAll;
        @include respond-to($xs) {
            margin: 0 auto;
            display: inline-block;
            float: none;
        }
        @include respond-to($xs, max) {
            background: $white;
            margin-top: 0;
            border-bottom: solid 2px #e7e7e7;
            padding-top: 40px;
        }
        >li {  
            @include transitionAll;
            @include respond-to($xs) {
                margin-top: 40px;
                &.logoMain {
                    @include transitionAll;
                    margin-top: -20px;
                    img {
                        height: 168px;
                        @include transitionAll;
                    }
                    >a {
                        padding-bottom: 0;
                        @include transitionAll;
                    }
                }
            }
            >a {
                font-size: 14px;
                color: $katthai; 
                text-transform: uppercase;
                @include transitionAll;
                @include montserrat; 
                font-weight: 700;
                padding-left: 22px;
                padding-right: 22px;
                &:hover { 
                    color: $black;
                } 
            }
        }
    }
    .container-fluid {
        padding-top: 15px;
        background: url(../img/pattern.png) repeat-x left -1px;
        @include transitionAll;
        @include respond-to($xs, max) {
            padding-top: 5px; 
        }
    }
}
.navbar-right {
    margin-top: 10px;
}
.navbar-default {
    @include respond-to($xs) {
        text-align: center;
    }
}
.navbar-default .navbar-brand {
    font-weight: 700;
    text-transform: uppercase;
    color: #838486;
    display: block;
    @include respond-to($xs, max) {
        padding: 0;
    }
    img {
        @include respond-to($xs, max) {
            height: 100px;
        } 
    }
}

.navbar.sticky {
    position: fixed;
    height: 80px;
    @include transitionAll;
    .navbar-nav>li {
        margin-top: 15px;
        @include transitionAll;
    }
    .navbar-nav>li.logoMain {
        margin-top: -20px;
        @include transitionAll;
    }
    li.logoMain img {
        height: 110px;
        @include transitionAll;
    }
    .container-fluid {
        padding-top: 10px;
        background: url(../img/pattern.png) repeat-x left -43px;
        @include transitionAll;
    }
}

.navSpacer {
    height: 100px;
}